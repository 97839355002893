import { datePickTypes } from 'element-plus';
import { createStore } from 'vuex'

export default createStore({
  state: {
    // 商品列表
    good_list: [
      {
        id: 1,
        name: '抢险车台班/台班/其中定额人工费（120）',
        price: 552.27,
        checked: true
      },
      {
        id: 2,
        name: '人工抢险台班/台班',
        price: 220.00,
        checked: true
      },
      {
        id: 3,
        name: '燃气表更换/块/其中定额人工费（45.06）',
        price: 415.29,
        checked: true
      },
      {
        id: 4,
        name: '氮气吹扫/m/其中定额人工费（1.29）',
        price: 3.02,
        checked: true
      },
      {
        id: 5,
        name: 'DN25丁字拆除检测恢复/处/其中定额人工费（69.96）',
        price: 119.34,
        checked: true
      },
      {
        id: 6,
        name: '停气恢复气密性检查/户/其中定额人工费（10.70）',
        price: 20.93,
        checked: true
      },
      {
        id: 7,
        name: '拆除路面/㎡/其中定额人工费（51.88）',
        price: 80.92,
        checked: true
      },
      {
        id: 8,
        name: '水泥混凝土/㎡/其中定额人工费（10.81）',
        price: 192.11,
        checked: true
      },
      {
        id: 9,
        name: '拆除人行道/㎡/其中定额人工费（8.02）',
        price: 25.79,
        checked: true
      },
      {
        id: 10,
        name: '人行道块料铺设/㎡/其中定额人工费（31.42）',
        price: 150.70,
        checked: true
      },
      {
        id: 11,
        name: '挖沟槽土方/m³/其中定额人工费（25.65）',
        price: 42.16,
        checked: true
      },
      {
        id: 12,
        name: '回填方/m³/其中定额人工费（7.68）',
        price: 14.74,
        checked: true
      },
      {
        id: 13,
        name: '回填沙/m³/其中定额人工费（27.89）',
        price: 361.51,
        checked: true
      },
      {
        id: 14,
        name: '余方弃置/m³/其中定额人工费（2.98）',
        price: 22.54,
        checked: true
      }
      
    ],
    // 添加购物车 购物车列表
    adds: []
  },
  getters: {
    // 实现购物车列表映射
    cartProducts(state) {
      return state.adds.map((item1) => {
        let product = state.good_list.find((item2) => { return item2.id == item1.id });
        return {
          id: product.id,
          name: product.name,
          price: product.price,
          checked: product.checked,
          num: item1.num,
          total_num: (product.price * item1.num).toFixed(2)
        }
      })
    },
    // 购物车总数量
    totalNum(state, getters) {
      let num = 0
      getters.cartProducts.forEach((item) => {
        num += item.num;
      });
      return num
    },
    // 购物车总价格
    totalPrice(state, getters) {
      // console.log("getters.cartProducts:", getters.cartProducts)
      let sum = 0
      getters.cartProducts.forEach((item) => {
        sum += parseFloat(item.total_num);
      });
      return sum
    },
  },
  mutations: {
    // 添加购物车操作
    addCart(state, data) {
      console.log(state)
      // 在购物车数组中根据对应查找，有 数量就+1 没有就行进行添加
      let recode = state.adds.find((item) => { return item.id == data.id })
      if (!recode) {//第一次添加
        state.adds.push({
          id: data.id,
          num: 1
        })
      } else {
        recode.num++
      }
    },

    //改变购物车商品数量
    numChange(state, data) {
      state.adds.forEach((item) => {
        if (item.id == data.id) {
          item.num = data.num
        }
      })
    },
    // 删除指定商品
    deleteProduct(state, date) {
      let index = state.adds.findIndex((item) => {
        if (item.id == date.id) {
          return true
        }
        return false
      })
      state.adds.splice(index, 1)
    },


    // 删除全部商品
    clearProducts(state) {
      state.adds = []
    }
  },
  actions: {
    // 添加购物车操作
    addCartAction({ commit }, payload) {
      commit('addCart', payload)
    },

    // 改变购物车的商品数量的操作
    numChangeAction({ commit }, payload) {
      commit('numChange', payload)
    },

    // 删除指定商品
    deleteProductAction({ commit }, data) {
      commit('deleteProduct', data)
    },

    // 清空购物车
    clearProduceAction({ commit }) {
      commit('clearProducts')
    }
  },
  modules: {
  },
  // plugins: [persistedstate({ storage: window.sessionStorage })]
})
